import React from 'react'
import styled, { css } from 'styled-components'

const enum PolygonCategory {
  Clean = '청결',
  Defect = '결품',
  Door = '문열림',
}

interface Polygon {
  coords: string
  category: string
  name: string
  status: string
  image_link: string
  color: string
}

interface Props {
  polygons: Polygon[]
}

const Container = styled.div`
  padding: 32px 24px 80px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9fb;
  gap: 32px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const OverviewHeader = styled.div`
  color: #787f89;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 8px;
`

const RedText = styled.span`
  color: #e05959;
  font-weight: 700;
`

const ListHeader = styled.div<{ showRedEllipsis?: boolean }>`
  display: flex;
  align-items: center;
  color: #565f6c;
  padding: 0 4px;
  font-size: 19px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 8px;

  ${(props) =>
    props.showRedEllipsis
      ? css`
          &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #e05959;
            margin-right: 8px;
          }
        `
      : css`
          justify-content: space-between;
        `}
`

const Counter = styled.div`
  font-size: 19px;
  font-weight: 500;
  line-height: 140%;
  color: #b1b5bb;
`

const SectionBox = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid #e9e9ea;
  background-color: #fff;
  font-size: 16px;
  color: #24282d;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`

const COLOR_CHIP_SIZE = 14
const COLOR_CHIP_MARGIN = 6
const ChipColor = {
  red: '#E05959',
  yellow: '#FAD232',
  green: '#5EB982',
}
type ChipProps = { status: 'green' | 'yellow' | 'red' }
const PolygonName = styled.div<ChipProps>`
  display: flex;
  gap: ${COLOR_CHIP_MARGIN}px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 140%;

  &::before {
    display: inline-block;
    content: '';
    width: ${COLOR_CHIP_SIZE}px;
    height: ${COLOR_CHIP_SIZE}px;
    border-radius: 4px;
    background-color: ${(props) => ChipColor[props.status]};
  }
`

const StatusText = styled.div`
  margin-left: ${COLOR_CHIP_SIZE + COLOR_CHIP_MARGIN}px;
  color: #787f89;
  font-weight: 400;
  line-height: 140%;
`

const ArrowButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #e9e9ea;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #f9f9fb;
  }
`

const getStatusText = ({ category, status }: Polygon) => {
  if (category === PolygonCategory.Clean) {
    return status === 'green' ? '청결함' : '확인 필요'
  }
  if (category === PolygonCategory.Defect) {
    return status === 'green' ? '결품 없음' : '결품 있음'
  }
  if (category === PolygonCategory.Door) {
    return status === 'green' ? '닫힘' : '열림'
  }
  return ''
}

const categoryList = [
  PolygonCategory.Defect,
  PolygonCategory.Clean,
  PolygonCategory.Door,
]

function OverviewSentence({
  category,
  polygons,
}: {
  category: string
  polygons: Polygon[]
}) {
  if (polygons.length === 0) {
    return null
  }
  const totalCount = polygons.length
  const problemCount = polygons.filter(({ status }) => status !== 'green').length

  if (category === PolygonCategory.Defect) {
    return (
      <div>
        <span>{totalCount}</span>대의 진열대 중
        <RedText>{` ${problemCount}대`}</RedText>에 결품이 있습니다.
      </div>
    )
  }
  if (category === PolygonCategory.Clean) {
    return (
      <div>
        <span>{totalCount}</span>개의 청결대상 중
        <RedText>{` ${problemCount}곳`}</RedText>이 청소가 필요합니다.
      </div>
    )
  }
  if (category === PolygonCategory.Door) {
    return (
      <div>
        <span>{totalCount}</span>개의 냉장고 중
        <RedText>{` ${problemCount}개`}</RedText>의 냉장고가 열려 있습니다.
      </div>
    )
  }
  return null
}

export default function Status({ polygons: polygonGroups }: Props) {
  const groupedPolygons = polygonGroups.reduce<
    Partial<Record<string, Polygon[]>>
  >((acc, polygon) => {
    acc[polygon.category] = [...(acc?.[polygon.category] ?? []), polygon]
    return acc
  }, {})

  return (
    <Container>
      <Section>
        <ListHeader showRedEllipsis>실시간 점포 현황</ListHeader>
        <SectionBox>
          {categoryList.map((category) => {
            const polygons = groupedPolygons?.[category]
            return polygons === undefined ? null : (
              <div key={category}>
                <OverviewHeader>{category}</OverviewHeader>
                <OverviewSentence
                  key={category}
                  category={category}
                  polygons={polygons}
                />
              </div>
            )
          })}
        </SectionBox>
      </Section>
      {categoryList.map((category) => {
        const polygons = groupedPolygons?.[category] ?? []
        return polygons.length === 0 ? null : (
          <Section key={category}>
            <ListHeader>
              <span>{`${category} 상세`}</span>
              <Counter>
                <span style={{ color: '#565f6c' }}>
                  {polygons.filter(({ status }) => status === 'green').length}
                </span>
                <span>/{polygons.length}</span>
              </Counter>
            </ListHeader>
            <SectionBox key={category}>
              {polygons.map((polygon) => (
                <ListItem key={polygon.name}>
                  <div>
                    <PolygonName
                      status={polygon.status as 'green' | 'yellow' | 'red'}
                    >
                      {polygon.name}
                    </PolygonName>
                    <StatusText>{getStatusText(polygon)}</StatusText>
                  </div>
                  <ArrowButton
                    onClick={() => {
                      window.location.href = polygon.image_link
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.5 2.5L10.5 8.00059L5.5 13.5"
                        stroke="#787F89"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </ArrowButton>
                </ListItem>
              ))}
            </SectionBox>
          </Section>
        )
      })}
    </Container>
  )
}
