/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

interface Polygon {
  coords: string
  category: string
  name: string
  status: string
  image_link: string
  site_name: string
  color: string
}

interface MiniMapProps {
  polygons: Polygon[]
  minimap: string
  site: string
}

const Container = styled.div`
  width: 100%;
  padding: 24px;
  background-color: #fff;
`

const CANVAS_HEIGHT = 326
const CanvasContainer = styled.div`
  width: 100%;
  height: ${CANVAS_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CanvasTitle = styled.div`
  color: #3d434d;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 24px;
`

const Tooltip = styled.div`
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
`

const polygonColor: { [key in string]: string } = {
  red: '#E05959b3',
  yellow: '#FAD232b3',
  green: '#5EB982b3',
}

type Size = {
  width: number
  height: number
}
function getScaleToFit({
  maxSize,
  imageSize,
}: {
  maxSize: Size
  imageSize: Size
}) {
  return Math.min(
    maxSize.height / imageSize.height,
    maxSize.width / imageSize.width,
  )
}

function scaleCoord(coord: number[], scale: number): [number, number] {
  if (coord.length !== 2) {
    // console.error('Invalid coord:', coord)
  }
  return [coord[0] * scale, coord[1] * scale]
}

export default function MiniMap({ polygons, minimap, site }: MiniMapProps) {
  const canvasContainerRef = useRef<HTMLDivElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [canvasMaxWidth, setCanvasMaxWidth] = useState(0)
  const [scaleToFit, setScaleToFit] = useState(1)
  const [tooltipInfo, setTooltipInfo] = useState<{
    text: string
    x: number
    y: number
  }>()

  const drawPolygon = useCallback(
    (ctx: CanvasRenderingContext2D, coords: string, color?: string) => {
      const coordsArray = coords.split(',').map(Number)
      ctx.beginPath()
      ctx.moveTo(...scaleCoord([coordsArray[0], coordsArray[1]], scaleToFit))

      for (let i = 2; i < coordsArray.length; i += 2) {
        ctx.lineTo(
          ...scaleCoord([coordsArray[i], coordsArray[i + 1]], scaleToFit),
        )
      }

      ctx.closePath()

      if (color !== undefined) {
        ctx.fillStyle = color
        ctx.fill()
      }
    },
    [scaleToFit],
  )

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setCanvasMaxWidth(entry.contentRect.width)
      })
    })
    if (canvasContainerRef.current) {
      resizeObserver.observe(canvasContainerRef.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    const imageElement = document.createElement('img')
    imageElement.src = minimap.startsWith('/') ? minimap : `/images/${minimap}`

    imageElement.onload = () => {
      if (canvasMaxWidth === 0) {
        // console.log('Canvas max width is 0')
        return
      }
      const canvas = canvasRef.current
      const ctx = canvas?.getContext('2d')

      if (!canvas || !ctx) {
        // console.error(
          // 'Drawing image failed: Canvas or context is not available',
        // )
        return
      }
      const scale = getScaleToFit({
        maxSize: {
          width: canvasMaxWidth,
          height: CANVAS_HEIGHT,
        },
        imageSize: { width: imageElement.width, height: imageElement.height },
      })
      setScaleToFit(scale)
      canvas.width = imageElement.width * scale
      canvas.height = imageElement.height * scale

      // 캔버스를 초기화
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // 이미지 캔버스에 그리기
      ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height)

      // 폴리곤 그리기
      polygons.forEach((polygon) => {
        drawPolygon(ctx, polygon.coords, polygonColor[polygon.status])
      })

      // // 캔버스 이미지 자동 저장 (백엔드로 전송)
      // const saveCanvasImage = async () => {
      //   const imageData = canvas.toDataURL('image/png')

      //   // 이미지 데이터를 백엔드로 전송
      //   try {
      //     const response = await axios.post(
      //       '/api/v1/save-image',
      //       { image: imageData, minimap },
      //       { withCredentials: true },
      //     )
      //     // console.log('Image saved:', response.data)
      //   } catch (error) {
      //     // console.error('Failed to save canvas image:', error)
      //   }
      // }

      // saveCanvasImage()
    }
    return () => {
      imageElement.remove()
    }
  }, [canvasMaxWidth, drawPolygon, minimap, polygons])

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')

    if (!canvas || !ctx) {
      // console.error(
      //   'Adding interaction failed: Canvas or context is not available',
      // )
      return
    }

    // 마우스 움직임에 따른 호버 효과
    canvas.addEventListener('mousemove', (event) => {
      const rect = canvas.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      let foundPolygon = false

      polygons.forEach((polygon) => {
        drawPolygon(ctx, polygon.coords)

        if (ctx.isPointInPath(x, y)) {
          foundPolygon = true
          setTooltipInfo({
            text: polygon.name,
            x: event.clientX,
            y: event.clientY,
          })
          canvas.style.cursor = 'pointer'
        }
      })

      if (!foundPolygon) {
        setTooltipInfo(undefined)
        canvas.style.cursor = 'default'
      }
    })

    // 캔버스 클릭 이벤트 추가
    canvas.addEventListener('click', (event) => {
      const rect = canvas.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      polygons.forEach((polygon) => {
        drawPolygon(ctx, polygon.coords)

        if (ctx.isPointInPath(x, y)) {
          window.location.href = polygon.image_link
        }
      })
    })
  }, [drawPolygon, polygons])

  return (
    <Container>
      <CanvasTitle>{site}</CanvasTitle>
      <CanvasContainer ref={canvasContainerRef}>
        <canvas style={{ margin: 'auto' }} ref={canvasRef} />
      </CanvasContainer>
      {tooltipInfo !== undefined && (
        <Tooltip
          style={{
            left: tooltipInfo.x + 15,
            top: tooltipInfo.y + 15,
          }}
        >
          {tooltipInfo.text}
        </Tooltip>
      )}
    </Container>
  )
}
