import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MiniMap from './minimap';
import Status from './status';
import Header from './header';

const BACKEND = '/api';

interface Site {
  name: string;
  minimap: string;
}

interface Polygon {
  coords: string;
  category: string;
  name: string;
  status: string;  
  image_link: string;
  site_name: string;
  color: string;
}

const App: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [site, setSite] = useState<Site | null>(null);
  const [polygons, setPolygons] = useState<Polygon[]>([]);

  useEffect(() => {
    if (!uuid) {
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.post(`${BACKEND}/v1/site`, { uuid }, { withCredentials: true });
        setSite(response.data.site);
        setPolygons(response.data.polygons);
      } catch (error) {
      }
    };
  
    fetchData();
  }, [uuid]);

  return (
    <div >
      {site && (
        <>
          <Header/>
          <MiniMap
            polygons={polygons}
            minimap={site.minimap}
            site={site.name}
          />
          <hr className="border-2 border-gray-300 my-6" />
          <Status
            polygons={polygons}
          />
        </>
      )}
    </div>
  );
};

export default App;